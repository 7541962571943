import React, { useState } from "react";
import Container from "../../components/container";
import Intro from "../../components/intro";
import { graphql, navigate } from "gatsby";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import OneQuestionForm from "../../components/OneQuestionForm";

export default function Index() {
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");

  const handleChangePassword = (e) => {
    setError(false);
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      password.toUpperCase().includes("LUA") ||
      password.toUpperCase().includes("LÚA")
    ) {
      setError(false);
      navigate("/");
    } else {
      setError(true);
    }
  };

  return (
    <Container>
      <section className="flex-col flex items-center md:justify-between mt-8 mb-16 md:mb-12">
        <h1 className="text-6xl font-bold tracking-tighter leading-tight md:pr-8 text-center">
          Accede a la prueba.
        </h1>
        <img
          className="mt-5 rounded-2xl "
          src="/taza.jpg"
          title="Borracho"
          alt="Borracho"
        />
        <OneQuestionForm
          question="Seguro que adivináis la contraseña para entrar?"
          validateResponse={(input) => {
            let normalizedInput = input
              .normalize("NFKD")
              .replace(/[\u0300-\u036f]/g, "");
            return normalizedInput.toLowerCase().includes("lua");
          }}
          onSuccess={() => navigate("/taza1/acertijo/borracho")}
        />
      </section>
      {/*<form onSubmit={handleSubmit}>*/}
      {/*  <label*/}
      {/*    htmlFor="password"*/}
      {/*    className="block text-md font-medium text-gray-700"*/}
      {/*  >*/}
      {/*    Seguro que adivináis la contraseña para entrar*/}
      {/*  </label>*/}
      {/*  <div className="mt-1 relative rounded-md shadow-sm">*/}
      {/*    <input*/}
      {/*      type="password"*/}
      {/*      name="password"*/}
      {/*      id="password"*/}
      {/*      className="block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"*/}
      {/*      // placeholder="you@example.com"*/}
      {/*      // defaultValue="adamwathan"*/}
      {/*      value={password}*/}
      {/*      onChange={handleChangePassword}*/}
      {/*      aria-invalid="true"*/}
      {/*      aria-describedby="email-error"*/}
      {/*    />*/}
      {/*    {error && (*/}
      {/*      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">*/}
      {/*        <ExclamationCircleIcon*/}
      {/*          className="h-5 w-5 text-red-500"*/}
      {/*          aria-hidden="true"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*  {error && (*/}
      {/*    <p className="mt-2 text-sm text-red-600 py-2" id="email-error">*/}
      {/*      No es tan difícil*/}
      {/*    </p>*/}
      {/*  )}*/}

      {/*  <button*/}
      {/*    type="submit"*/}
      {/*    className=" mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
      {/*  >*/}
      {/*    Probar suerte*/}
      {/*  </button>*/}
      {/*</form>*/}
    </Container>
  );
}
